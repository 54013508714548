import(/* webpackMode: "eager" */ "/tmp/build_c611aad9/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
import(/* webpackMode: "eager" */ "/tmp/build_c611aad9/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/tmp/build_c611aad9/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/tmp/build_c611aad9/src/app/[locale]/Home.module.scss");
import(/* webpackMode: "eager" */ "/tmp/build_c611aad9/src/components/AboutUsSection/index.jsx");
import(/* webpackMode: "eager" */ "/tmp/build_c611aad9/src/components/ContactUsSection/index.jsx");
import(/* webpackMode: "eager" */ "/tmp/build_c611aad9/src/components/IntroSection/index.jsx");
import(/* webpackMode: "eager" */ "/tmp/build_c611aad9/src/components/Navbar/index.jsx");
import(/* webpackMode: "eager" */ "/tmp/build_c611aad9/src/components/PortfolioSection/index.jsx");
import(/* webpackMode: "eager" */ "/tmp/build_c611aad9/src/components/ReviewsSection/ReviewsSection.module.scss");
import(/* webpackMode: "eager" */ "/tmp/build_c611aad9/src/components/ReviewsSection/SwiperReviewsCard/index.jsx");
import(/* webpackMode: "eager" */ "/tmp/build_c611aad9/src/components/ServicesSection/ServicesSection.module.scss");
import(/* webpackMode: "eager" */ "/tmp/build_c611aad9/src/components/ServicesSection/ServiceSectionCardItem/ServiceSectionCardItem.module.scss");
import(/* webpackMode: "eager" */ "/tmp/build_c611aad9/src/components/Footer/Footer.module.scss");
import(/* webpackMode: "eager" */ "/tmp/build_c611aad9/src/components/WhyChooseSection/index.jsx");
import(/* webpackMode: "eager" */ "/tmp/build_c611aad9/src/images/footer/Facebook.png");
import(/* webpackMode: "eager" */ "/tmp/build_c611aad9/src/images/footer/Instagram.png");
import(/* webpackMode: "eager" */ "/tmp/build_c611aad9/src/images/footer/LinkedIn.png");
import(/* webpackMode: "eager" */ "/tmp/build_c611aad9/src/images/navbar/logo.svg");
import(/* webpackMode: "eager" */ "/tmp/build_c611aad9/src/shared/LinkItem/index.jsx")