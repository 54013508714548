import logoImage from 'images/navbar/logo.svg'

export const navbarData = {
  logoImage,
  links: [
    { id: 'home' },
    { id: 'how-we-are' },
    { id: 'services' },
    { id: 'portfolio' },
    { id: 'reviews' },
    { id: 'contact' },
  ],
}
