export const whyChooseSectionData = {
  cards: [
    {
      title: '0',
      text: '0',
    },
    {
      title: '1',
      text: '1',
    },
    {
      title: '2',
      text: '2',
    },
    {
      title: '3',
      text: '3',
    },
    {
      title: '4',
      text: '4',
    },
  ],
}
